import React from 'react'
import '../css/rahul-styles.css'
import Header from '../components/header'
import Footer from '../components/footer'
import libraryImg from '../images/blog-detail/library.png'
import laptopImg from '../images/blog-detail/laptop.png'
import Layout from '../components/layout'

const BlogDetail = () => {
  return (
    <div>
      <Layout>
        {/* <Header /> */}
          <div className="container-fluid blog-detail-container">
            <div className="row blog-detail-main-heading-text-row">
              <div className="col-md-6 col-lg-10">
                <h3 className="blog-detail-sub-heading"> Posted on April 12, 2021 </h3>
                <h1 className="blog-detail-main-heading-text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi, amet!</h1>
                <h3 className="blog-detail-sub-heading"> Blogs / Category 1 / Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
              </div>
            </div>
            <div className="row">
              <div className="card shadow w-100 blog-detail-main-card">
                <img className="img-fluid blog-detail-library-img" src={libraryImg} alt="Books" />
                <div className="row blog-detail-card-content">
                  <div className="col-md-8 blog-detail-topic-body">
                    <h5 className="blog-detail-topic-heading">
                      Lorem ipsum dolor sit amet, consectetur adipiscing eli
                    </h5>
                    <p className="blog-detail-topic-para-text" >
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <p className="blog-detail-topic-para-heading">
                      Lorem ipsum dolor sit ameti
                    </p>
                    <p className="blog-detail-topic-para-text" >
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.
                    </p>
                    <img src={laptopImg} alt="laptop-image" />
                    <p className="blog-detail-image-label mt-2"> Image: Lorem ipsum dolor sit ameti </p>
                    <p className="blog-detail-topic-para-heading">
                      Lorem ipsum dolor sit ameti
                    </p>
                    <p className="blog-detail-topic-para-text" >
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="row blog-detail-side-menu">
                      <div className="col-sm-6 col-md-12 mb-4">
                        <div className="card shadow blog-detail-similar-blogs-card">
                          <p className="blog-detail-similar-blogs" > Similar Blogs </p>
                          <a href="#"> <p className="blog-detail-similar-blogs-title"> natus error sit voluptatem totam rem aperiam, eque </p> </a>
                          <p className="blog-detail-similar-blogs-posted-date" >
                            Posted on April 12, 2021
                          </p>
                          <hr style={{ color: "#626E81" }} />
                          <a href="#"> <p className="blog-detail-similar-blogs-title"> natus error sit voluptatem totam rem aperiam, eque </p> </a>
                          <p className="blog-detail-similar-blogs-posted-date" >
                            Posted on April 12, 2021
                          </p>
                          <hr style={{ color: "#626E81" }} />
                          <a href="#"> <p className="blog-detail-similar-blogs-title"> natus error sit voluptatem totam rem aperiam, eque </p> </a>
                          <p className="blog-detail-similar-blogs-posted-date" >
                            Posted on April 12, 2021
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-12 blog-detail-interested-container">
                        <div className="card shadow blog-detail-form-enquiry-card">
                          <p> Interested in what you see? Get started and study in your preferred destination? </p>
                        <a href="#" className='text-center'>
                          <button
                            type="button"
                            className="btn blog-detail-interested-button"
                          >GET STARTED</button>
                        </a>
                        </div>
                      </div>
                    </div>
        
        
        
                  </div>
                </div>
              </div>
            </div>
            <div className="row blog-detail-form">
              <div className="col-md-6">
                <div className="row ">
                  <div className="col-12">
                    <p className="blog-detail-form-heading">
                      Leave a comment
                    </p>
                  </div>
                  <div className="col-md-6 blog-detail-form-inputs">
                    <label className="form-label" > FIRST NAME </label>
                    <input className="form-control" type="text" />
                  </div>
                  <div className="col-md-6 blog-detail-form-inputs">
                    <label className="form-label" > E-MAIL </label>
                    <input className="form-control" type="email" />
                  </div>
                  <div className="col-12 blog-detail-form-inputs">
                    <label className="form-label" > COMMENTS </label>
                    <textarea className="form-control" rows="3" type="text" style={{border: 'none', borderRadius: "10px"}} > </textarea>
                  </div>
                <a href="#">
                    <button
                      type="button"
                      className="btn btn-warning blog-detail-comment-button"
                    >SUBMIT</button>
                  </a>
                </div>
        
              </div>
            </div>
          </div>
        {/* <Footer /> */}
      </Layout>
    </div>
  )
}

export default BlogDetail
